
import { defineComponent, onMounted, nextTick, ref, onActivated } from "vue";

export default defineComponent({
  setup() {
    const carouse = ref(null);
    /**重置轮播图高度 */
    function resizCarouseItemHeight() {
      const doms = document.querySelectorAll(".carouse-item");
      doms.forEach((dom: any) => {
        if (dom.offsetWidth < 640) {
          dom.style.backgroundSize = "cover";
          dom.style.height = "14rem";
          return;
        }
        dom.style.backgroundSize = "100%";
        dom.style.height = dom.offsetWidth * 0.31 + "px";
      });
    }
    onMounted(() => {
      nextTick(() => {
        resizCarouseItemHeight();
        window.removeEventListener("resize", resizCarouseItemHeight);
        window.addEventListener("resize", resizCarouseItemHeight);
      });
    });
    return {
      carouse,
      //轮播图的内容列表
      carouselList: [
        {
          imgUrl: require("../../../assets/home/home-bg.png"),
          message: "专注校园营养配餐",
          sloagen: "共享智慧 共享安全 分享快乐 分享爱",
        },
        {
          imgUrl: require("../../../assets/home/home-bg2.png"),
          message: "致力于智能智慧化软件及<br />互联网产品开发",
          sloagen: "共享智慧 共享安全 分享快乐 分享爱",
        },
      ],
    };
  },
});

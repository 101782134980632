import { createStore } from "vuex";

export default createStore({
  state: {
    globalScroll: null,
    loadingBar: null,
  },
  getters: {
    getGlobalScroll: (state) => {
      return state.globalScroll;
    },
    loadingBar: (state) => {
      return state.loadingBar;
    },
  },
  mutations: {
    setGlobalScroll: (state, el) => {
      state.globalScroll = el;
    },
    setLoadingBar:(state,el)=>{
      state.loadingBar =el;
    }
  },
  actions: {},
  modules: {},
});

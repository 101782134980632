
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    src: {
      type: String,
      default: require("../../../assets/qrcode/kxh-code.png"),
    },
    message: {
      type: String,
      default: "微信服务号",
    },
  },
});

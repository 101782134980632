
import { defineComponent } from "vue";
import JcCarouse from "./JcCarouse/index.vue";
import JcProdCar from "./JcProdCar/index.vue";
import { useLoadingBar } from "naive-ui";
import { useStore } from "vuex";
import wowmixin from "../../mixins/wow";
export default defineComponent({
  name: "home",
  components: { JcCarouse, JcProdCar },
  props: {
    p: {
      type: [String, Number],
      default: 2,
    },
  },
  setup() {
    const store = useStore();
    const loadingBar = useLoadingBar();
    store.commit("setLoadingBar", loadingBar);
    wowmixin();
    return {
      myFw: "我们的服务",
      sliders: [
        {
          message: "智能教学",
        },
        {
          message: "智能作业",
        },
        {
          message: "家校沟通",
        },
        {
          message: "教务管理",
        },
        {
          message: "交易服务平台",
        },
        {
          message: "健康安全",
        },
        {
          message: "企业服务平台",
        },
        {
          message: "专业配送",
        },
        {
          message: "商品购买",
        },
        {
          message: "优惠立减",
        },
        {
          message: "积分赚取",
        },
        {
          message: "会员专享",
        },
      ],
      about: "关于我们",
      aboutMsg:
        "北京华盛互联科技有限公司位于北京市丰台区，致力于智能智慧化软件及互联网产品开发，旗下拥有学生餐开心虎平台APP、互联网+明厨亮灶系统晟校APP、课堂情景再现教贝APP，公司秉承技术创新、互惠共赢、诚信为本的服务理念，与您携手共赢未来！",
      certification: "资质证书",
      prodCenter: "产品中心",
      prodMsg:
        "集团公司拥有一大批智能软硬件产品，智能闸机，智能卡，智能班牌，校牌，智能摄像头，智能传输系统软件，智能教务管理软件，智能教学研软件，智能师生评价系统，家校沟通等。",
      prods: {
        kxh: {
          title: "开心虎",
          msg: "智能管理：大数据、云计算的开发应用，实现从下单到采购到生产到配送全流程智慧化的统计分析，让中央厨房的管理……",
        },
        xyb: {
          title: "学优宝",
          msg: "学优宝APP是一款课堂辅助类 工具，教师上课时可以针对性的录制知识点……",
        },
        zxx: {
          title: "灶小鲜",
          msg: "灶小鲜是一款开心虎旗下的商城软件，包括商品购买、优惠立减活动……",
        },
      },
    };
  },
});

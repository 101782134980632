import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space-y-2" }
const _hoisted_2 = { class: "text-gray-500 text-center text-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_image = _resolveComponent("n-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_n_image, {
      class: "block w-20 border bg-clip-content mx-auto",
      src: _ctx.src
    }, null, 8, ["src"]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.message), 1)
  ]))
}
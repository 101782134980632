import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bb0d0190"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "space-y-3 text-center text-black" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "space-y-3 text-center text-white" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_carousel = _resolveComponent("n-carousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_n_carousel, {
      "show-arrow": "",
      autoplay: "",
      ref: "carouse",
      class: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "carouse-item flex items-center justify-around",
          style: _normalizeStyle({ backgroundImage: 'url(' + _ctx.carouselList[1].imgUrl + ')' })
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
              class: "text-base md:text-5xl font-black tracking-wider leading-none",
              innerHTML: _ctx.carouselList[1].message
            }, null, 8, _hoisted_3),
            _createElementVNode("div", {
              class: "text-xs md:text-lg font-medium text-gray-400 tracking-widest",
              innerHTML: _ctx.carouselList[1].sloagen
            }, null, 8, _hoisted_4)
          ])
        ], 4),
        _createElementVNode("div", {
          class: "carouse-item flex items-center justify-around",
          style: _normalizeStyle({ backgroundImage: 'url(' + _ctx.carouselList[0].imgUrl + ')' })
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", {
              class: "text-base md:text-5xl font-black tracking-wider",
              innerHTML: _ctx.carouselList[0].message
            }, null, 8, _hoisted_6),
            _createElementVNode("div", {
              class: "text-xs md:text-lg font-medium tracking-widest",
              innerHTML: _ctx.carouselList[0].sloagen
            }, null, 8, _hoisted_7)
          ])
        ], 4)
      ]),
      _: 1
    }, 512)
  ]))
}
import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-579dcaee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full overflow-x-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_bar = _resolveComponent("header-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_n_loading_bar_provider = _resolveComponent("n-loading-bar-provider")!
  const _component_footer_bar = _resolveComponent("footer-bar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_header_bar, {
      class: _normalizeClass(['jc-header', _ctx.headerShadow ? 'shadow-xl' : ''])
    }, null, 8, ["class"]),
    _createVNode(_component_n_loading_bar_provider, null, {
      default: _withCtx(() => [
        _createElementVNode("main", _hoisted_1, [
          _createVNode(_component_router_view)
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_footer_bar, { class: "jc-footer" })
  ]))
}
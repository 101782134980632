import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "home-container rounded-lg shadow-xl bg-white z-1 p-4 relative md:-m-5 md:mx-auto" }
const _hoisted_2 = { class: "w-full h-10 text-lg font-black text-black text-center" }
const _hoisted_3 = { class: "w-full h-full grid grid-cols-3 sm:grid-cols-4 gap-5 gap-y-10 p-2 pb-4" }
const _hoisted_4 = { class: "text-sm font-black text-gray-600 wow animate__animated animate__slideInDown" }
const _hoisted_5 = { class: "home-container bg-white mt-10 sm:mt-20 wow animate__animated animate__slideInUp" }
const _hoisted_6 = { class: "w-full h-10 text-lg font-black text-black text-center" }
const _hoisted_7 = { class: "flex flex-col items-center lg:flex-row lg:items-start" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "flex-grow px-5 lg:pl-5 lg:pr-32 text-xs text-gray-500 align-bottom pt-10" }
const _hoisted_10 = { class: "home-container bg-white mt-10 sm:mt-20 wow animate__animated animate__slideInUp" }
const _hoisted_11 = { class: "w-full h-10 text-lg font-black text-black text-center" }
const _hoisted_12 = {
  class: "w-full grid grid-cols-3 gap-5 gap-y-10 p-2 pb-4",
  style: {"display":"flex","justify-content":"space-around"}
}
const _hoisted_13 = { class: "w-full grid grid-cols-4 gap-5 gap-y-10 p-2 pb-4" }
const _hoisted_14 = {
  class: "w-full grid grid-cols-1 gap-5 gap-y-10 p-2 pb-4",
  style: {"display":"flex","justify-content":"space-around"}
}
const _hoisted_15 = { class: "w-full sm:bg-gray-100 wow animate__animated animate__slideInUp" }
const _hoisted_16 = { class: "home-container mt-10 flex p-2" }
const _hoisted_17 = { class: "justify-center flex-col" }
const _hoisted_18 = { class: "w-full h-10 text-lg text-center md:text-left font-black text-black" }
const _hoisted_19 = { class: "block px-5 md:px-0 text-gray-500 text-xs md:w-11/12" }
const _hoisted_20 = { class: "grid px-5 md:px-0 grid-cols-1 md:grid-cols-3 gap-5 py-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_jc_carouse = _resolveComponent("jc-carouse")!
  const _component_n_avatar = _resolveComponent("n-avatar")!
  const _component_n_image = _resolveComponent("n-image")!
  const _component_jc_prod_car = _resolveComponent("jc-prod-car")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_jc_carouse, { class: "relative wow animate__animated animate__slideInDown" }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.myFw), 1),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sliders, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "flex justify-around items-center space-y-3 flex-col cursor-pointer"
          }, [
            _createVNode(_component_n_avatar, {
              size: 60,
              src: require('../../assets/icons/sliderIcon' + (index + 1) + '.png'),
              class: _normalizeClass(['bg-transparent wow animate__animated', 'animate__rollIn']),
              round: ""
            }, null, 8, ["src"]),
            _createElementVNode("div", _hoisted_4, _toDisplayString(item.message), 1)
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.about), 1),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("img", {
          src: require('../../assets/home/header.png'),
          class: "block w-56 rounded-lg h-auto flex-shrink"
        }, null, 8, _hoisted_8),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("p", null, _toDisplayString(_ctx.aboutMsg), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.certification), 1),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_n_image, {
          src: require('../../assets/home/djzs01.jpg'),
          class: "w-64"
        }, null, 8, ["src"]),
        _createVNode(_component_n_image, {
          src: require('../../assets/home/djzs02.jpg'),
          class: "w-64"
        }, null, 8, ["src"]),
        _createVNode(_component_n_image, {
          src: require('../../assets/home/djzs03.jpg'),
          class: "w-64"
        }, null, 8, ["src"])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_n_image, {
          src: require('../../assets/home/zlzs01.jpg'),
          class: "w-56"
        }, null, 8, ["src"]),
        _createVNode(_component_n_image, {
          src: require('../../assets/home/zlzs02.jpg'),
          class: "w-56"
        }, null, 8, ["src"]),
        _createVNode(_component_n_image, {
          src: require('../../assets/home/zlzs03.jpg'),
          class: "w-56"
        }, null, 8, ["src"]),
        _createVNode(_component_n_image, {
          src: require('../../assets/home/zlzs04.jpg'),
          class: "w-56"
        }, null, 8, ["src"])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_component_n_image, {
          src: require('../../assets/home/zhengshu.jpg'),
          class: "w-1/2"
        }, null, 8, ["src"])
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.prodCenter), 1),
          _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.prodMsg), 1)
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createVNode(_component_jc_prod_car, {
            title: _ctx.prods.kxh.title,
            msg: _ctx.prods.kxh.msg,
            topath: "/kaixinhu",
            imgsrc: require('../../assets/home/prod-kxh.png'),
            class: "space-y-1"
          }, null, 8, ["title", "msg", "imgsrc"]),
          _createVNode(_component_jc_prod_car, {
            title: _ctx.prods.xyb.title,
            msg: _ctx.prods.xyb.msg,
            topath: "/xueyoubao",
            imgsrc: require('../../assets/home/prod-jb.png')
          }, null, 8, ["title", "msg", "imgsrc"]),
          _createVNode(_component_jc_prod_car, {
            title: _ctx.prods.zxx.title,
            msg: _ctx.prods.zxx.msg,
            topath: "/zaoxiaoxian",
            imgsrc: require('../../assets/home/prod-zxx.png')
          }, null, 8, ["title", "msg", "imgsrc"])
        ])
      ])
    ])
  ]))
}
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space-y-2 relative pb-10" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "text-base font-medium" }
const _hoisted_4 = { class: "text-xs text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_avatar = _resolveComponent("n-avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.imgsrc,
      class: "flex-shrink"
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.msg), 1),
    _createVNode(_component_n_avatar, {
      src: require('../../../assets/home/right-row.png'),
      size: 30,
      rouned: "",
      class: "bg-transparent cursor-pointer absolute bottom-0",
      onClick: _ctx.to
    }, null, 8, ["src", "onClick"])
  ]))
}
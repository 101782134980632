import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white w-full" }
const _hoisted_2 = { class: "grid grid-cols-1 divide-y md:grid-cols-3 md:divide-x md:divide-y-0 home-container py-10" }
const _hoisted_3 = { class: "flex items-center justify-center md:justify-evenly" }
const _hoisted_4 = { class: "box-border flex" }
const _hoisted_5 = { class: "w-10 mt-2" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "flex-grow p-2" }
const _hoisted_8 = { class: "h-5 text-sm" }
const _hoisted_9 = { class: "h-8 text-xl sm:texts-xl md:text-sm lg:text-xl font-black font-medium" }
const _hoisted_10 = { class: "text-xs text-gray-500" }
const _hoisted_11 = { class: "flex items-center justify-center" }
const _hoisted_12 = { class: "w-100 h-100 flex flex-col box-border" }
const _hoisted_13 = { class: "flex flex-row p-2" }
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "pl-2" }
const _hoisted_16 = { class: "h-6" }
const _hoisted_17 = { class: "text-gray-500 text-xs" }
const _hoisted_18 = { class: "flex flex-row p-2" }
const _hoisted_19 = ["src"]
const _hoisted_20 = { class: "pl-2" }
const _hoisted_21 = { class: "h-6" }
const _hoisted_22 = { class: "text-gray-500 text-xs" }
const _hoisted_23 = { class: "grid grid-cols-2 gap-x-2 xl:grid-cols-4 items-center py-3 px-2 xl:py-0 xl:pl-5" }
const _hoisted_24 = {
  class: "bg-gray-100 text-gray-400 text-center text-xs",
  style: {"height":"2/5rem","line-height":"2.5rem"}
}
const _hoisted_25 = {
  href: "https://beian.miit.gov.cn",
  target: "_blank",
  style: {"color":"#0f89f5"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_qr_code = _resolveComponent("qr-code")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("img", {
              src: require('../../assets/phone.png'),
              alt: "..."
            }, null, 8, _hoisted_6)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.text.phone.text), 1),
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.text.phone.phone), 1),
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.text.phone.time), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("img", {
              class: "w-5 h-4",
              src: require('../../assets/post.png')
            }, null, 8, _hoisted_14),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.text.post.text), 1),
              _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.text.post.email), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("img", {
              class: "w-4 h-5",
              src: require('../../assets/address.png')
            }, null, 8, _hoisted_19),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.text.address.text), 1),
              _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.text.address.address), 1)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_23, [
        _createVNode(_component_qr_code, {
          src: _ctx.text.qrCode.kxhWx.src,
          message: _ctx.text.qrCode.kxhWx.message
        }, null, 8, ["src", "message"]),
        _createVNode(_component_qr_code, {
          src: _ctx.text.qrCode.kxhApp.src,
          message: _ctx.text.qrCode.kxhApp.message
        }, null, 8, ["src", "message"])
      ])
    ]),
    _createElementVNode("div", _hoisted_24, [
      _createElementVNode("a", _hoisted_25, _toDisplayString(_ctx.text.beian), 1)
    ])
  ]))
}
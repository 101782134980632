import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import {
  create,
  NButton,
  NDropdown,
  NIcon,
  NScrollbar,
  NCarousel,
  NAvatar,
  NImage,
  NLoadingBarProvider,
  NBackTop,
} from "naive-ui";
import "./index.css";
import "./middleware";
import "animate.css";
import store from "./store";
const naive = create({
  components: [NButton, NDropdown, NIcon, NScrollbar, NCarousel, NAvatar, NImage, NLoadingBarProvider, NBackTop],
});
createApp(App).use(store).use(naive).use(router).mount("#app");
